<template >
    <div >
  <v-navigation-drawer
	  v-model="mini"
	  absolute
	  bottom
	  right
	  clipped
	  app
	  fixed
  >
    <v-list dense>
      <v-list-item-group
		 mandatory
		 v-model="selectedItem"
		 :color="this.$vuetify.theme.themes.light.primary.darken1"
	 >

        <v-list-item
		   v-for="item in itemsSection(1)"
		   :key="item.title"
		   @click="clickRouter(item.route)"
	   >

          <v-list-item-icon >
            <v-icon >{{ item.icon }}</v-icon >
          </v-list-item-icon >

          <v-list-item-content >
            <v-list-item-title >
              {{ $t(`navigation.${item.title}`) }}
              </v-list-item-title >
          </v-list-item-content >
        </v-list-item >
		<v-list-item
			class="p-mt-3"
			style="    background: #cddfe0;"
			v-if=" isAdmin"
			@click="toogleColelChange"
		>

          <v-list-item-icon >
       <v-icon >mdi-office-building-marker</v-icon >
          </v-list-item-icon >

          <v-list-item-content >
            <v-list-item-title >
			&nbsp;-> &nbsp;&nbsp;{{ colel.colel.id }}
              </v-list-item-title >
          </v-list-item-content >
        </v-list-item >
		   <v-list-item
			   v-for="item in itemsSection(2)"
			   :key="item.title"
			   @click="clickRouter(item.route)"
		   >

          <v-list-item-icon >
            <v-icon >{{ item.icon }}</v-icon >
          </v-list-item-icon >

          <v-list-item-content >
            <v-list-item-title >
              {{ $t(`navigation.${item.title}`) }}
              </v-list-item-title >
          </v-list-item-content >
        </v-list-item >


      </v-list-item-group >
    </v-list >
	   </v-navigation-drawer >

	  <OverlayPanel
		  ref="op"
		  v-if="colel"
	  >
<!--		style="z-index:100"-->
<Button
	class="p-m-3 p-button-secondary p-button-sm"
	icon="pi pi-arrow-up"
	iconPos="right"
	@click="navigateColel(true)"
/>
<Button
	class="p-m-3 p-button-secondary p-button-sm"
	icon="pi pi-arrow-down"
	iconPos="right"
	@click="navigateColel(false)"
/>
		<Listbox
			listStyle="max-height:250px"
			style="width:10rem"
			id="colel-pick"
			:filter="true"
			v-model="colel.id"
			:options="colelIds"
			:placeholder="$t('appBar.selectColel')"
			@input="inputColel"
		/>

<!--            <Dropdown-->
<!--                style="z-index:100"-->
<!--                id="colel-pick"-->
<!--                :filter="true"-->
<!--                v-model="colel.id"-->
<!--                :options="colelIds"-->
<!--                :placeholder="$t('appBar.selectColel')"-->
<!--                @input="inputColel"-->
<!--            />-->

</OverlayPanel >
</div >
</template >

<script >
import {getIndexRoute} from "@/router";
import {mapGetters, mapState} from "vuex";

export default {
  name: "navigation",
  components: {},
  props: {
    items: Array,
    mini: Boolean
  },
  data() {
    return {
      isDrawer: false,
      selectedItem: null,
      miniProp: this.mini

    };
  },
  watch: {
    mini(a) {
      this.miniProp = a;
    }
  },
  created() {
    this.selectedItem = getIndexRoute(window.location.href);
  },
  methods: {
    updateColel(event) {
      this.$emit('updateColel', event)
    },
    itemsSection(section) {
      return this.items.filter(item => item.section === section)
    },
    clickRouter(newRoute) {
      this.$router.push(newRoute).catch(() => {
        this.$router.go(0);
      });
    },
    inputColel(event) {
      this.$refs.op.toggle(event);
      console.log("evend", event)

      this.$emit('updateColel', event)
      this.selectedItem = getIndexRoute(window.location.href);

    },
    navigateColel(upOrDown) {
      const navigateTo = upOrDown ? +1 : -1
      const length = this.colelIds.length
      const currentIndex = this.colelIds.findIndex(
          colel => colel === this.colel.colel.id
	 )
      const targetColel = this.colelIds[(currentIndex - navigateTo + length) %
      length]
      this.$emit('updateColel', targetColel)
    },
    toogleColelChange(event) {
      this.$refs.op.toggle(event);
      setTimeout(() => {
        document.getElementById('colel-pick')
                .dispatchEvent(new MouseEvent(
                    "click",
                    {
                      bubbles: true,
                      cancellable: true
                    }));
      }, 0)

    }
  },
  computed: {
    ...mapState(["user", 'colel', 'colelIds']),
    ...mapGetters(["isAdmin"]),
    isLarge() {
      return this.$vuetify.breakpoint.name !== 'xs'
    }
    // showDrawer() {
    //     return this.isLarge || this.drawer
    // }
  }
};
</script >
