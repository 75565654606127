import axios from "axios";

export  function contractionColelIds(colelIds) {
    const colelIdsEnum = []
    colelIds.map((c) => colelIdsEnum.push(c.id))
    return colelIdsEnum
}

export async function getSettingsAttendanceTypes(colel_id) {
    return  await axios.get(`/attendance/${colel_id}/types`);
}

export async function getColelIds() {
    return  await axios.get(`/manager/colel/ids`);
}

export async function getMyColel() {
    return await axios.get(`/init`);
}

export async function getColelById(colel_id) {
    return await axios.get(`/colel/${colel_id}`);
}

