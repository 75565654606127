

<template>
   <v-card :style="zIndex" :disabled="isLoading ? isLoading : false"
   :loading="isLoading ? isLoading : false"
    elevation="2" outlined>
      <v-card-title>
        <v-icon
          large
          left
          :color="(color ? color : this.$vuetify.theme.themes.light.primary.darken1)"
        >
          {{`${icon}`}}
        </v-icon>
        <span class="text-h6 font-weight-light"
          >&nbsp;&nbsp; {{title}}</span
        >
        <v-spacer>

        </v-spacer>
    <slot name="extra-header"></slot>

      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->

      </v-card-title>
      <v-divider class="mx-4"></v-divider>
    <slot name="content"></slot>

    <slot name="footer"></slot>
    </v-card>
</template>

<script>
export default {
  name: "card",
   props: {
    isLoading: Boolean,
    icon: String,
    title: String,
    color: String,
	zindex: Number
  },
  computed: {
    zIndex() {
      return `z-index: ${this.zindex}`;
    }
  }
};
</script>