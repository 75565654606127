import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Password from "primevue/password";
import Card from "primevue/card";
import Checkbox from 'primevue/checkbox';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import SelectButton from 'primevue/selectbutton';
import Divider from 'primevue/divider';
import Badge from 'primevue/badge';
import Dropdown from 'primevue/dropdown';
import SplitButton from 'primevue/splitbutton';
import Menu from 'primevue/menu';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Calendar from 'primevue/calendar';
import Textarea from 'primevue/textarea';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import OverlayPanel from 'primevue/overlaypanel';
import ScrollPanel from 'primevue/scrollpanel';
import Sidebar from 'primevue/sidebar';
import Slider from 'primevue/slider';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import BlockUI from 'primevue/blockui';
import Listbox from 'primevue/listbox';
export const initPrimeVue = (Vue) => {
  Vue.use(ToastService);
  Vue.component("Toast", Toast);
  Vue.component("Checkbox", Checkbox);
  Vue.component("BlockUI", BlockUI);
  Vue.component("Textarea", Textarea);
  Vue.component("Accordion", Accordion);
  Vue.component("AccordionTab", AccordionTab);
  Vue.component("ScrollPanel", ScrollPanel);
  Vue.component("Calendar", Calendar);
  Vue.component("TriStateCheckbox", TriStateCheckbox);
  Vue.component("OverlayPanel", OverlayPanel);
  Vue.component("Sidebar", Sidebar);
  Vue.component("Slider", Slider);
  Vue.component("Listbox", Listbox);
  Vue.component("SplitButton", SplitButton);
  Vue.component("Menu", Menu);
  Vue.component("Dropdown", Dropdown);
  Vue.component("Badge", Badge);
  Vue.component("Divider", Divider);
  Vue.component("SelectButton", SelectButton);
  Vue.component("DataTable", DataTable);
  Vue.component("Row", Row);
  Vue.component("Column", Column);
  Vue.component("ColumnGroup", ColumnGroup);
  Vue.component("Card", Card);
  Vue.component("Button", Button);
  Vue.component("InputText", InputText);
  Vue.component("InputNumber", InputNumber);
  Vue.component("Password", Password);
};
