export default {
  login: {
    title: "ברוכים הבאים",
    subTitle: "מערכת ניהול כולל חמ״ש",
    username: "שם מזהה",
    password: "סיסמא",
    login: "התחבר",
    //
    loginErrorTitle: "התחברות נכשלה",
    loginErrorForbidden: "שם המשתמש או הסיסמה שגויים",
    loginErrorDetails: "בעיות בחיבור לשרת"
  },
  app: {
    changeColelDetail: 'שינית את מצב המערכת ל- {colel_id}',
    changeColel: ' שינוי כולל'


  },
  appBar: {
    changeColel: "שנה כולל",
    selectColel: "בחר כולל",
    logout: "התנתק",
    themeMode: {
      light: "עבור למצב כהה",
      dark: "עבור למצב בהיר"
    }
  },
  navigation: {
    colel: "כוללים",
    person: "אברכים",
    test: "מבחנים",
    presence: "דוח נוכחות",
    suggestions: "המלצות",
    systemSettings: "הגדרות",
    reports: "דוחות"

  },
  settings: {
    lockTitle: "נעילת דיווח לכל הכוללים",
    lock: "נעל",
    amount_to_be_paid_for_minutes_group: " שווי איחור לכל X דקות",
    minutes_group: " X דקות",
    minimal_attendance: "נוכחות מינימלית",
    daily_absence_value: "שווי חיסור יומי ",
    monthly_amount: " גובה מלגה חודשית",
    titleColelLevel: " הגדרות חישוב",
    titleColelTest: " הגדרות מבחנים",
    oral_exam_pay: "מאמר בעל פה - ש״ח",
    written_test_pass_grade: "מבחן בכתב - ציון עובר",
    written_test_pay: " מבחן בכתב - ש״ח",
    pickLevel: "בחר קבוצת הגדרות",
    save: "שמור"

  },
  colelDetails: {
    title: "פרטי הכולל",
    owner: " שם המנהל",
    first_name: "שם פרטי של האחראי",
    last_name: "שם משפחה של האחראי",
    full_name: "מנהל הכולל",
    phone_number: "מספר טלפון",
    mail_address: "כתובת למשלוח דואר",
    address_description: "כתובת הלימודים"
  },
  introColelSettings: {
    continue: "המשך",
    checkBox: "אני מאשר שהנתונים אלו נכונים ומעודכנים"
  },
  calendar: {
    ownerLock: "ערוך דיווח",
    lockSuccess: "חודש זה דווח בהצלחה",
    unlockSuccess: "ניתן לערוך חודש זה",
    lockErrorTitle: "קרתה תקלה בשליחת דיווח לחודש זה",
    lockErrorTitleAllDays: "יש למלא נוכחות על כל ימי החודש",
    ownerOpen: "שלח דיווח",
    menager: "ניהול",
    isSendPictureOfColel: "שלחתי תמונות מהכולל"
  },
  colelHours: {
    title: " שעות פעילות הכולל",
    day1: "ראשון",
    day2: "שני",
    day3: "שלישי",
    day4: "רביעי",
    day5: "חמישי",
    day6: "שישי",
    startHour: "שעת התחלה",
    endHour: "שעת סיום"
  },
  deleteDialog: {
    title: "מחיקה",
    ok: "מחק",
    cancel: "ביטול",
    personDeleted: '{first_name} {last_name}  נמחק בהצלחה'

  },
  editDialog: {
    personEdited: '{first_name} {last_name}  עודכן בהצלחה',
    personAdded: '{first_name} {last_name} התווסף בהצלחה',
    personEditedError: '{first_name} {last_name}  קרתה תקלה בעדכון האברך',

  },
  personDialog: {
    title: "פרטי אברך",
    post: "שמור",
    cancel: "ביטול",
    personDeleted: '{first_name} {last_name}  התווסף בהצלחה'
  },

  testReport: {
    confirmationLabel: '  שים לב שאתה מדווח על חודש {month} ',

    title: "דוח מבחנים",
    name: "שם",
    oral_exam_grade: "מבחן בע״פ",
    written_exam_grade: "מבחן בכתב",
    written_exam_gradePlaceholder: "ציון מבחן בכתב",
    custom_messagePlaceholder: "ציין הערות",
    custom_message: "הערות",
    notReportYet: "לא דווח",
    past: "עבר",
    notPast: "לא עבר",
    postTestErrorDetail: "לא הצלחנו לעדכן את המידע",
    postTestErrorTitle: "מצטערים"
  },
  reports: {
    download: "הורד",
    title: "דוחות",
    colel: "כולל",
    type: "סוג דוח"
  },
  presenceReport: {
    askAllColel: "אתה בטוח רוצה להעתיק?",
    locked: "חודש זה נעול",
    title: "דיווח נוכחות",
    phone_number: "טלפון",
    cancel: "ביטול",
    post: "שמור",
    lock: "נעל דיווח",
    allPresence: "  דווח על {amountAttendance} מתוך {amountPerson} אברכים",
    allPersonsPresenceReport: " דווח על כל האברכים בארץ כ-",
    allPresenceReport: "דווח",
    guests: "לומדים נוספים",
    guestsRequest: "חובה",
    name: 'שם',
    first_name: 'שם פרטי',
    present: "נוכח",
    miss: "החסיר",
    empty: "ריק",
    allowed: 'ח.מ.',
    holiday: 'חג',
    last_name: 'שם משפחה',
    subtraction_allowed: 'ח.מ',
    attendance_type: 'נוכחות',
    minutes_late: 'נוכחות דקות ',
    //
    postAllSuccessTitle: 'מגניב',
    postAllSuccessDetail: 'עדכנת את כולם כ- {type}'
  },
  colelDialog: {
    createSuccessfully: "פרטי הכולל נשמרו בהצלחה",
    createError: "קרתה תקלה בשמירת פרטי הכולל",
    title: "פרטי כולל",
    post: "שמור",
    cancel: "ביטול",
    mail_address: "כתובת דואר",
    lastEntry: "כניסה אחרונה",
    phone_number: "טלפון",
    id: "שם הכולל",
    street: "רחוב",
    apartment: "מס׳ בית",
    city: "עיר",
    special_message_content: "תוכן ההודעה",
    special_message_title: "כותרת הודעה",
    message_color_type: "צבע הודעה",
    owner: "מנהל",
    address_description: "תיאור הכתובת",
    password: "סיסמה"
  },
  colelMessage: {
    color: 'צבע',
    title: "הודעת כולל",
    special_message_title: "כותרת",
    special_message_content: "גוף ההודעה",
    colel_level: "רמת הכולל",
    post: "שמור",
    cancel: "ביטול"
  },
  colelSettings: {
    title: "הגדרות כולל",
    show_calender: "הצג לוח שנה",
    multiple_update: "עדכון מרובה",
    report_month_earlier: "דווח חודש קודם",
    extract_report: "ייצא דוח",
    manager_lock: "נעילת מנהל"
  },
  personTable: {
    accept: "קבל הצעה",
    edit: "ערוך אברך",
    add: "הוסף אברך",
    restore: "החזר אברך",
    reject: "דחה הצעה",
    delete: "מחק אברך",
    loading: 'טוען אברכים, אנא המתן...',
    title: "רשימת אברכים",
    titleRecommendations: "המלצות אברכים",
    filterBy: 'חפש לפי {name} ',
    askDelete: 'בטוח שאתה רוצה להסיר את {first_name} {last_name} ?',
    colel_id: "כולל",
    last_name: "משפחה",
    first_name: "שם פרטי",
    phone_number: "טלפון",
    id: "ת.ז.",
    street: "רחוב",
    apartment: "מס׳ בית",
    city: "עיר",
    bank_name: "בנק",
    bank_branch: "סניף",
    bank_account: "מס׳ חשבון",
    name_to_pay_for: "שם המוטב",
    ez_number: "נתמך",
    join_date: "תאריך הצטרפות",
    leaving_date: "תאריך עזיבה"
  },
  suggestionsTable: {

    "title": "המלצות",
    "ez": "נתמך",
    "ezTitle": "מילוי נתמך",
    "ezDescription": "יש להכניס מספר איזי",

    "titleRecommendations": "המלצות",
    "colel_id": "שם הכולל",
    "entity_type": "כולל/אברך",
    "status_type": "מצב הצעה",
    "suggestion_type": "פעולה",
    "create_date": "תאריך בקשה",
    "execution_date": "תאריך אישור",
    filterBy: 'חפש לפי {name} ',
    details: 'פרטי הבקשה ',
    sendSuggestion: 'אשר הבקשה ',
    reject: 'דחה',
    accept: 'אשר '


  },
  colelTable: {

    report_lock: "מצב נעילה",
    attendance_status: "מצב מילוי",
    last_login: "כניסה אחרונה לפני",
    accept: "קבל הצעה",
    edit: "ערוך כולל",
    hoursButton: "הצג/ערוך שעות כולל",
    settings: "הצג/ערוך הגדרות כולל",
    messageButton: "הצג/ערוך הודעת כולל",
    add: "הוסף כולל",
    restore: "החזר כולל",
    reject: "דחה הצעה",
    delete: "מחק כולל",
    loading: 'טוען כוללים, אנא המתן...',
    title: "רשימת כוללים",
    titleRecommendations: "המלצות כוללים",
    filterBy: 'חפש לפי {name} ',
    askDelete: 'בטוח שאתה רוצה להסיר את {last_name} {first_name} ',
    hours: "שעות",
    mail_address: "כתובת דואר",
    lastEntry: "כניסה אחרונה",
    phone_number: "טלפון",
    colel: {
      id: "שם הכולל",
      street: "רחוב",
      apartment: "מס׳ בית",
      city: "עיר",
      mail_address: "כתובת דואר"

    },
    colel_details: {
      owner: {
        first_name: "שם פרטי",
        last_name: "שם משפחה",
        phone_number: "טלפון"

      }
    },
    "colelTable.colel.id": "שם הכולל",
    "colelTable.colel.city": "עיר",
    "colelTable.colel.street": "רחוב",
    "colelTable.colel.apartment": "מס׳ בית",
    "colelTable.colel.mail_address": "כתובת דואר",
    "colelTable.colel_details.owner.first_name": "שם פרטי",
    "colelTable.colel_details.owner.last_name": "שם משפחה",
    "colelTable.colel_details.owner.phone_number": "טלפון",
    id: "שם הכולל",
    street: "רחוב",
    apartment: "מס׳ בית",
    city: "עיר",
    special_message_content: "תוכן ההודעה",
    special_message_title: "כותרת הודעה",
    message_color_type: "צבע הודעה",
    owner: {
      first_name: "שם פרטי",
      last_name: "שם משפחה",
      phone_number: "טלפון"

    },
    address_description: "תיאור הכתובת",
    empty: "ריק",
    process: "חלקית",
    full: "מלא",
  },
  timePickerDialog: {
    ok: "אישור",
    cancel: "ביטול"
  },
  form: {
    ok: "שמור",
    fieldsErrorTitle: "כמה מהשדות חסרים או שגויים",
    cancel: "ביטול",
    required: "*שדה חובה",
    notValidId: "*יש להכניס 9 ספרות",
    regex: "*ערכים לא מתאימים"
  },
  suggestions: {
    savedSuccessfully: "ההצעה נשמרה בהצלחה",
    sendSuggestion: "שלח הצעה"
  },

  errors: {
    duplicatePersonId: 'קיים כבר אברך עם תעודת זהות זהה',
    duplicateEz: 'קיים כבר אברך עם מספר נתמך זהה',
    rangeToCopy: 'יש לבחור טווח תאריכים להעתקה',
    pasteField: 'העתקת הנתונים לא צלחה',
    rangeToPaste: 'יש לבחור טווח תאריכים להדבקה',
    gettingReportNotComplete: 'לא ניתן להנפיק דוח על חודש שלא דווח במלואו',
    fileNotFound: 'לא נמצאו קבצים להורדה',
    noAllFilesReceived: 'שים לב - יש כוללים לא נעולים שלא ירדו בקובץ',
    gettingReport: 'אירעה שגיאה בהורדת הדוח {typerepot}',
    gettingReportSDetails: 'שגיאה',
    error: 'שגיאה',
    internalServerError: 'שגיאה כללית',
    saturdayReportError: 'לא ניתן לדווח על שבת',
    attendanceReportError: 'דיווח שגוי'
  },
  success: {
    rangeToCopyTitle: 'נבחר טווח להעתקה ',
    rangeToPasteTitle: 'נבחר טווח להדבקה ',
    rangeToCopy: 'מ - {startDate}\nעד - {endDate}',
    gettingReport: '{typerepot} הורד בהצלחה '
  }
};
