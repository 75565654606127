<template >
  <v-app class="app">
    <colel-toast />
    <div v-if="isLogin">
      <navigation
		 @updateColel="updateColel"
		 v-if="canShowNavigation"
		 :mini="isDrawer"

		 :items="pages"
	 />

      <appBar
		 v-on:onDrawer=" isDrawer = !isDrawer"
		 :title="getUserName"
		 class="d-none d-sm-flex"
	 />
	   <!--        mobile-->
      <navigationXs
		 v-if="canShowNavigation && this.$vuetify.breakpoint.name === 'xs'"

		 class="d-lg-none"
		 :isDrawer="isDrawer"
		 :items="pages"
		 v-on:closed="isDrawer = !isDrawer"
	 />
      <appBarXs
		 @updateColel="updateColel"
		 :title="getUserName"
		 class="d-sm-none"
		 v-on:onDrawer="isDrawer = !isDrawer"
	 />
	   <!--        mobile-->

    </div >


    <v-main class="container-navigate"  style="height: 100vh; overflow-y: auto;
    overflow-x: hidden;">
		         <router-view class="container-navigate"/>
    </v-main >

  </v-app >
</template >

<script >
import axios from "axios";

import(process.env.VUE_APP_CSS);
import {mapState, mapActions, mapGetters} from "vuex";
import {routerItems} from "@/router";
import {
  getSettingsAttendanceTypes,
  getMyColel,
  getColelIds,
  contractionColelIds, getColelById
} from "./App";

import {
  navigationXs,
  navigation,
  appBarXs,
  appBar
} from "./views/frame/index";
import constants from "@/constants";
import Vue from "vue";

export default {
  name: "App",
  components: {
    navigationXs,
    navigation,
    appBarXs,
    appBar
  },
  async mounted() {
    this.initLocale()
    this.initTheme()
    // this.initToken()
    // await this.initColel()
  },
  created() {
    window.addEventListener('resize', () => this.setScreenSize())
    this.$vuetify.breakpoint.name !== 'xs' ? this.isDrawer = true : ''
  },
  data() {
    return {
      selectedDate: null,
      isDrawer: false,
      mini: false
    };
  },
  computed: {
    pages() {
      if (this.isAdmin) {
        return routerItems.admin
      } else {
        return routerItems.user
      }
    },
    ...mapGetters(["isAdmin",  "getAccessToken", "getUserName",
				"isLogin",
                    "canShowNavigation"
                  ]),
    ...mapState(["user", 'colel', 'colelIds', "screenSize"])
  },
  watch: {
    async getAccessToken(newValue, oldValue) {
      // await this.initColel()
    }
  },


  methods: {
    ...mapActions(["setScreenSize", "setRole", "setColelIds", 'setColel',
                    'setAccessToken', "setSettingsAttendanceTypes"
                  ]),
    updateColel(event) {
      this.initAppSettings(event)
      this.initColel(event)
    },
    initTheme() {
      const targetTheme = localStorage.getItem("theme");
      this.$vuetify.theme.dark = targetTheme === "dark";
      let themeElement = document.getElementById("theme-link");

      themeElement.setAttribute(
          "href",
          themeElement.getAttribute("href").replace('light', targetTheme)
      );
    },
    initToken() {
      const token = localStorage.getItem("token")
      axios.defaults.headers.common['token'] = token
      this.setAccessToken(token)
    },
    async initColel(colel_id) {
      try {
          const {data} = await getColelById(colel_id)
       await this.setColel(data)
        Vue.ls.set('lastColel', colel_id);
        this.$toast.add({
                          severity: 'success',
                          summary:
                              this.$t('app.changeColel'),
                          detail:
                              this.$t('app.changeColelDetail', {colel_id}),
                          life: constants.LIFE_TOAST
                        });
      } catch (e) {
        this.$toast.add({
                          severity: 'error',
                          summary:
                              this.$t('app.changeColel'),
                          detail:
                          e,
                          life: constants.LIFE_TOAST
                        });
        if (e?.response?.status === 403) {
          localStorage.removeItem('token')
          this.setAccessToken(null)
          this.$router.push("/login");
        }
      }
    },
    async initAppSettings(colelId) {
      try {
        const {data} = await
            getSettingsAttendanceTypes(colelId ? colelId :
                                           this.colel?.colel?.id)
        this.setSettingsAttendanceTypes(data.types)

      } catch (e) {
        console.log("error", e)
      }

    },
    initLocale() {
      this.$primevue.config.locale.dayNamesMin =
          ["א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ש'"];
      this.$primevue.config.locale.weekHeader = "שבוע";

      this.$primevue.config.locale.monthNames =
          ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט",
            "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"
          ];
      this.$primevue.config.locale.monthNamesShort =
          ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט",
            "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"
          ];
    }
  }
};
</script >

<style scoped>

.app {
    font-family: "Rubik", cursive !important;

    /*, Helvetica, Arial, sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3250;
    direction: rtl !important;
    /* margin: 150px; */
}


/*<*/
style >
.v-overlay {
    /*z-index: 1009 !important;*/
}

>>> .p-overlaypanel {
    z-index: 2009 !important;
}


.container-navigate {
/*    display: inline-block;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    min-height: 80vh;*/
/*    vertical-align: middle;*/
/*    overflow: hidden;*/
/*    position: relative;*/
/*    !* background-image: url("../assets/background.jpg"); *!*/
/*    background-position: top;*/
}

</style >
