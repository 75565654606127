

<template>
    <div>
        <Toast :baseZIndex="99999" :breakpoints="{'920px': {width: '100%',
        right: '0',
        left: '0'}}" position="bottom-left"/>

</div>
</template>

<script>
export default {
  name: "toast",

   props: {

  },
};
</script>