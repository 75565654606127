<template >
  <div >
      <!-- COLEL_CHANGE -->
<!--    <v-tooltip-->
<!--	    v-if="isAdmin "-->
<!--        bottom-->
<!--        z-index="1010"-->
<!--    >-->
<!--      <template v-slot:activator="{ on, attrs }">-->
<!--        <v-btn-->
<!--            class="p-ml-2"-->
<!--            @click="toogleColelChange"-->
<!--            icon-->
<!--            v-bind="attrs"-->
<!--            v-on="on"-->
<!--            small-->
<!--        >-->
<!--          <v-icon >mdi-office-building-marker</v-icon >-->
<!--        </v-btn >-->
<!--      </template >-->
<!--      <span >{{ $t("appBar.changeColel") }}</span >-->
<!--    </v-tooltip >-->
      <!-- DARK -->
    <v-tooltip
        class="p-p-0"
        bottom
        z-index="1010"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="p-ml-2"
            @click="setThemeMode"
            icon
            v-bind="attrs"
            v-on="on"
            small
        >
          <v-icon >{{ themeModeIcon }} </v-icon >
        </v-btn >
      </template >
      <span >{{ $t(themeModeToolBar) }}</span >
    </v-tooltip >
      <!-- LOGOUT -->
    <v-tooltip
        bottom
        z-index="1010"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            small
            @click="logoutFun"
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon >mdi-arrow-left-bold-box-outline</v-icon >
        </v-btn >
      </template >
      <span >{{ $t("appBar.logout") }}</span >
    </v-tooltip >

      <OverlayPanel ref="op" v-if="colel">
<!--		style="z-index:100"-->
		<Listbox
				listStyle="max-height:250px"
				style="width:10rem"
              id="colel-pick"
               :filter="true"
                v-model="colel.id"
                :options="colelIds"
               :placeholder="$t('appBar.selectColel')"
               @input="inputColel"/>
<!--            <Dropdown-->
<!--                style="z-index:100"-->
<!--                id="colel-pick"-->
<!--                :filter="true"-->
<!--                v-model="colel.id"-->
<!--                :options="colelIds"-->
<!--                :placeholder="$t('appBar.selectColel')"-->
<!--                @input="inputColel"-->
<!--            />-->

</OverlayPanel >
  </div >
</template >

<script >

import {mapActions, mapGetters, mapState} from "vuex";
import constants from "@/constants";
import {routerItems} from "@/router";

export default {
  name: "buttonsBar",
  created() {
    // const a = document.getElementById('x')
    // a.click()
  },
  computed: {
    ...mapGetters(["getRole",'isAdmin' ]),
    themeModeToolBar() {
      if (!this.$vuetify.theme.dark) {
        return "appBar.themeMode.light";
      } else {
        return "appBar.themeMode.dark";
      }
    },
    themeModeIcon() {
      if (!this.$vuetify.theme.dark) {
        return "mdi-weather-night";
        // return "mdi-moon-waxing-crescent";
      } else {
        return "mdi-white-balance-sunny";
      }
    },
    ...mapState(["user", 'colel', 'colelIds'])
  },
  methods: {
    setThemeMode() {
      const currentTheme = localStorage.getItem("theme");
      const targetTheme = currentTheme === "dark" ? "light" : "dark";
      this.$vuetify.theme.dark = targetTheme === "dark";
      localStorage.setItem("theme", targetTheme);

      let themeElement = document.getElementById("theme-link");

      themeElement.setAttribute(
          "href",
          themeElement.getAttribute("href").replace(currentTheme, targetTheme)
      );
    },
    logoutFun() {
      this.logout();
      localStorage.removeItem("token");
      localStorage.removeItem(constants.SUBMIT_COLEL_SETTINGS)
      this.setSubmitSettings(false)
      this.$router.push("/login");
    },
    inputColel(event) {
      this.$refs.op.toggle(event);
      this.$emit('updateColel', event)
    },
    toogleColelChange() {
      this.$refs.op.toggle(event);
      setTimeout(() => {
        // const a =  document.getElementsByClassName('p-dropdown')
        document.getElementById('colel-pick').dispatchEvent(new
                                                            MouseEvent("click",
                                                                       {
                                                                         bubbles: true,
                                                                         cancellable: true
                                                                       }));
        // console.log("p-dropdown",a)
        // a[0].click();
      }, 0)

    },

    ...mapActions(["logout", "setSubmitSettings"])
  },
  data() {
    return {
      a: ["2",'3'],
      selectedCity: "moshe",
    }
  }
};
</script >

<style scoped>

#colel-pick {
    width: 35vw;
}

>>> .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    /*padding-right: 0 !important;*/
    margin-right: 0 !important;
}

</style >
