<template>
<!--  style="z-index:1012"-->
 <v-app-bar   clipped-right   fixed  app   dense :color="this.$vuetify.theme.themes.light.primary.darken1">
      <v-app-bar-nav-icon  @click.stop="onDrawer" ></v-app-bar-nav-icon>
   <imgBar/>
           <v-spacer></v-spacer>

   <titleBar  :title="title"/>
      <v-spacer></v-spacer>
  <buttonsBar  @updateColel="updateColel"/>
    </v-app-bar>
</template>

<script>
import {buttonsBar, titleBar, imgBar} from './bar'
export default {
  name: "appBarXs",
  props: {
    title: String,
  },
components:{
 buttonsBar, titleBar, imgBar
},
methods:{
  onDrawer(){
    this.$emit("onDrawer")
  },
  updateColel(event) {
    this.$emit('updateColel', event)
  }
}
}
</script>

