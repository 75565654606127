import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store'
import constants from "@/constants";
import axios from "axios";
import {
  contractionColelIds, getColelById,
  getColelIds,
  getMyColel,
  getSettingsAttendanceTypes
} from "@/App";

Vue.use(VueRouter);

const thereToken = () => {
  const token = localStorage.getItem("token")
  return token !== null
}

const pageNotFoundGuard = (to, from, next) => {
  if (to) {
    next();
  } else {
    next('/')
  }
}
const tokenGuard = async (to, from, next) => {
  if (store.state?.user?.role === null) {
      const token = localStorage.getItem("token")
    await store.dispatch('setToken', token)
    if (token){
      axios.defaults.headers.common['token'] = token
      try {
        const {data: colel} = await getMyColel()
        await store.dispatch('setRole', colel.type)
        let colelToSave = colel
        let settingsToSave = colel.types
        const colelAtLocalStorage = Vue.ls.get('lastColel')
        console.log(colelAtLocalStorage, "colelAtLocalStorage")
        if (colelAtLocalStorage){
          try {
            const {data} = await getColelById(colelAtLocalStorage)
             colelToSave = data
             settingsToSave = data.types
          } catch (e) {
            console.log(e)
          }
        }
        const {data: settings} = await
            getSettingsAttendanceTypes(colelToSave?.colel?.id)
        await store.dispatch('setColel', colelToSave)
        await store.dispatch('setSettingsAttendanceTypes', settings)


        // initAppSettings("cc")
        // initColel("cc")
        // await store.dispatch('setSettingsAttendanceTypes', settings.types)

        if (colel.type === constants.ADMIN) {

          const {data: colelIds} = await
              getColelIds()
          await store.dispatch('setColelIds', contractionColelIds(colelIds))
        }
      } catch (e) {
        if (e?.response?.status === 403) {
          localStorage.removeItem('token')
          await store.dispatch('setAccessToken', null)
          next('/login')

        }
      }


      next();

    } else {
      next('/login')
    }
  } else {
    next();

  }

}

const notTokenGuard = (to, from, next) => {
  if (!thereToken()) {
    next();
  } else {
    next(from)
  }
}

const adminGuard = (to, from, next) => {
  if (store.state?.user?.role === constants.ADMIN) {
    next();
  } else {
    next(from)
  }
}

const notAdminGuard = (to, from, next) => {
  if (store.state?.user?.role !== constants.ADMIN) {
    next();
  } else {
    next(from)
  }
}

const submitColelSettingsGuard = (to, from, next) => {
      if (store.state.user.role !== constants.ADMIN) {
        if (store.getters.canShowNavigation) {
          next();
        } else {
          next('/colel-settings')

        }
      } else {
        next();

      }

}
const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import("@/views/home/Home.vue"),
  //    beforeEnter: async (to, from, next)  => {
  //     await tokenGuard(to, from, next)
  //     // submitColelSettingsGuard(to, from, next)
  //
  //   }
  // },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/Login.vue"),
    beforeEnter: (to, from, next) => {
      notTokenGuard(to, from, next)
    }
  },
  {
    path: "/person",
    name: "person",
    component: () => import("@/views/person/Person.vue"),
    beforeEnter: async (to, from, next) => {
      await tokenGuard(to, from, next)
      submitColelSettingsGuard(to, from, next)
    }
  },
  {
    path: "/colel",
    name: "colel",
    component: () => import("@/views/colel/Colel.vue"),
    beforeEnter: async (to, from, next) => {
      await tokenGuard(to, from, next)
      adminGuard(to, from, next)
    //
    }
  },
  {
    path: "/suggestions",
    name: "suggestions",
    component: () => import("@/views/suggestions/Suggestions.vue"),
    beforeEnter:  async  (to, from, next) => {
     await tokenGuard(to, from, next)
      submitColelSettingsGuard(to, from, next)
    }
  },
  {
    path: "/system_settings",
    name: "systemSettings",
    component: () => import("@/views/systemSettings/SystemSettings.vue"),
    beforeEnter:  async  (to, from, next) => {
     await tokenGuard(to, from, next)
      adminGuard(to, from, next)
    //
    }
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("@/views/reports/Report.vue"),
    beforeEnter:  async  (to, from, next) => {
     await tokenGuard(to, from, next)
      submitColelSettingsGuard(to, from, next)

    //
    }
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/test/Test.vue"),
    beforeEnter: async (to, from, next) => {
      await tokenGuard(to, from, next)
      submitColelSettingsGuard(to, from, next)
    }
  },
  {
    path: "/presence",
    name: "presence",
    component: () => import("@/views/presence/Presence.vue"),
    beforeEnter: async (to, from, next) => {
     await tokenGuard(to, from, next)
      submitColelSettingsGuard(to, from, next)

    }
  },
  {
    path: "/colel-settings",
    name: "IntroColelSettings",
    component: () => import("@/views/introColelSettings/IntroColelSettings.vue"),
    beforeEnter: async (to, from, next) => {
     await tokenGuard(to, from, next)
      notAdminGuard(to, from, next)
    }
  }
];

const router = new VueRouter({
                               routes
                             });

router.beforeEach((to, from, next) => {
  if (to.name !== null) {
    next()
  } else {
        next('/suggestions')
  }
})

function getIndexRoute(path) {
  const EXTRA_ITEM_MENU = 1
  const isAdmin = store.state.user.role === constants.ADMIN
  switch (path.split("#")[1]) {
    case '/presence':
      if (isAdmin) {
        return 2 + EXTRA_ITEM_MENU
      } else {
        return 1
      }
    case '/person':
      if (isAdmin) {
        return 4 + EXTRA_ITEM_MENU
      } else {
        return 3
      }
    case '/colel':
      return 1
    case '/test':
      if (isAdmin) {
        return 3 + EXTRA_ITEM_MENU
      } else {
        return 2
      }
      case '/suggestions':
      if (isAdmin) {
        return 0
      } else {
        return 0
      }
      case '/system_settings':
      if (isAdmin) {
        return 5 + EXTRA_ITEM_MENU
      } else {
        return 1
      }
      case '/reports':
      if (isAdmin) {
        return 6 + EXTRA_ITEM_MENU
      } else {
        return 4
      }
    default:
      break;
  }
}

const routerItems = {
  admin: [
    {title: "suggestions", icon: "mdi-content-save-all", route: "/suggestions", section: 1},
    {title: "colel", icon: "mdi-google-classroom", route: "/colel", section: 1},
    {title: "presence", icon: "mdi-calendar-clock", route: "/presence", section: 2},
    {title: "test", icon: "mdi-notebook-edit-outline", route: "/test", section: 2},
    {title: "person", icon: "mdi-account-box-multiple", route: "/person", section: 2},
    {title: "systemSettings", icon: "mdi-cog", route: "/system_settings", section: 2},
    {title: "reports", icon: "mdi-chart-box", route: "/reports", section: 2}
  ],
  user: [

    {title: "suggestions", icon: "mdi-content-save-all", route: "/suggestions", section: 1},
    {title: "presence", icon: "mdi-calendar-clock", route: "/presence", section: 1},
    {title: "test", icon: "mdi-notebook-edit-outline", route: "/test", section: 1},
    {title: "person", icon: "mdi-account-box-multiple", route: "/person", section: 1},
    {title: "reports", icon: "mdi-chart-box", route: "/reports", section: 1},
  ]

}


export {router, routerItems, getIndexRoute}
