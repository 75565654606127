<template>
 
 <v-navigation-drawer
 style="z-index:1011"
      v-on:input="onDrawrInput"
            v-model="isDrawer"
      bottom
       right
       clipped
            app
       fixed
       touchless
    >
     
 <v-list dense>
      <v-list-item-group mandatory v-model="selectedItem"
        :color="this.$vuetify.theme.themes.light.primary.darken1">
        <v-list-item 
          v-for="item in items"
          :key="item.title"
           @click="clickRouter(item.route)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
 <v-list-item-title>
              {{ $t(`navigation.${item.title}`) }}
              </v-list-item-title >
          </v-list-item-content>
        </v-list-item>
         </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import { getIndexRoute } from "@/router";


export default {
 name: "navigationXs",
props: {
    items: Array,
    isDrawer: Boolean
  },
   data() {
    return {
      selectedItem: null,

    };
  },
    created() {
    this.selectedItem = getIndexRoute(window.location.href);
  },
  methods:{
      onDrawrInput(isOpen){
          if(!isOpen) {
              this.$emit("closed",false)
          }
      },
      clickRouter(newRoute) {
      this.$router.push(newRoute).catch(() => {
        this.$router.go(0);
      });
    },
  }
  
}
</script>

