import Hebcal from "hebcal";

export const dateToFullHebrew = (date) => {
    return `${dayToHebrew(Hebcal.HDate(new Date(date)).getDay() + 1)} ${Hebcal.gematriya(Hebcal.HDate(new Date(date)).getDate(
        "h"
    ))} ${Hebcal.HDate(new Date(date)).getMonthName(
        "h"
    )}\u00A0\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0\u00A0${new Date(date).getDate()}.${new Date(date).getMonth() + 1
        }.${new Date(date).getFullYear().toString().slice(2, 4)}`;
}

export const dateToServer = (date) => {
    const isDateObject = date instanceof Date

    return date && isDateObject ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : date
}

export const dateToMonthAndYear = (date) => {
    return `${date.getFullYear()}/${date.getMonth() + 1}`
}

export const dateToDayAndMonthAndYear = (date) => {
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
}

export const monthToHebrew = (date) => {
    return Hebcal.HDate(new Date(date) ).getMonthName("h")
}

const dayToHebrew = (day) => {
    switch (day) {
        case 0:
            return 'שבת'
        case 1:
            return 'ראשון'
        case 2:
            return 'שני'
        case 3:
            return 'שלישי'
        case 4:
            return 'רביעי'
        case 5:
            return 'חמישי'
        case 6:
            return 'שישי'
        case 7:
            return 'שבת'
    }
}

