import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import {router} from "./router";
import vuetify from "./plugins/vuetify";
import PrimeVue from "primevue/config";
import i18n from "@/translate/translate";
import VueLocalStorage from 'vue-ls';

const options = {
  namespace: 'colel_'
};

Vue.use(VueLocalStorage, options);
// My Components
import toast from "@/components/toast";
import card from "@/components/card";
import table from "@/components/table";
import deleteDialog from "@/components/deleteDialog";
import calendar from "@/components/calendar";

Vue.component("colel-toast", toast);
Vue.component("colel-card", card);
Vue.component("colel-table", table);
Vue.component("colel-table2", table);
Vue.component("colel-delete-dialog", deleteDialog);
Vue.component("colel-calendar", calendar);

import "primeflex/primeflex.css";
// import "primevue/resources/themes/vela-blue/theme.css";
 import Vuesax from 'vuesax'
// import VueWindowSize from 'vue-window-size';

// Vue.use(VueWindowSize);
  import 'vuesax/dist/vuesax.css' //Vuesax styles
  Vue.use(Vuesax, {
    // options here
  })

import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { initPrimeVue } from "@/primeVue";
import axios from "axios";
import VueAlertify from 'vue-alertify';

// Vue.use(VueAlertify);
Vue.use(PrimeVue);


// Vue.use(PrimeVue, {
//   locale: {
//     dayNames :	["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
//     dayNamesShort :	["1", "2", "Tue", "Wed", "Thu", "Fri", "Sat"],
//     dayNamesMin :	["1","Mo","Tu","We","Th","Fr","Sa"],
//     monthNames:	["January","February","March","April","May","June","July","August","September","October","November","December"]
//   }
// });
initPrimeVue(Vue);
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

Vue.use(axios);



Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  router,
  vuetify,
  local: {
    TIER_ALL: 7//$options.local.myVar
  },
  render: (h) => h(App),
}, ).$mount("#app");
