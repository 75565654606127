<template>
 <v-app-bar  style="flex-direction: column;"
            clipped-right   fixed  app
            :color="this.$vuetify.theme.themes.light.primary.darken1" dense>

	      <v-app-bar-nav-icon  @click.stop="onDrawer" ></v-app-bar-nav-icon>
   <imgBar/>

   <titleBar :title="title"/>
      <v-spacer></v-spacer>
  <buttonsBar/>
    </v-app-bar>
</template>

<script>
import {buttonsBar, titleBar, imgBar} from './bar'
export default {
 name: "appBar",
  props: {
    title: String,
  },
components:{
 buttonsBar, titleBar, imgBar
},
  methods: {
    onDrawer(){
      this.$emit("onDrawer")

    },
    updateColel(event) {
      this.$emit('updateColel', event)
    }
  }
}
</script>

