
<template>
  <div class="deleteDialog">
    <v-dialog style="z-index: 1030" v-model="isShow" max-width="600"
               v-on:click:outside="clodeDialog">
      <colel-card
        :title="$t('deleteDialog.title')"
        :icon="'mdi-trash-can'"
        :color="'error'"
      >
        <template v-slot:content>
              <v-card-text>
            {{title}}
              </v-card-text>
          <v-card-text>
            <v-col cols="6">
			 <colel-calendar
				 :yearNavigator="true" yearRange="2000:2030"
				 :monthNavigator="true"
				 :manualInput="false"
				 :dateFormat="dateformat"
				 touchUI
				 v-model="date"
				 :showWeek="false"
			 />

            </v-col>
          </v-card-text>
          <v-card-text>
            {{"יש לבחור תאריך. ברירת המדל  היא לתאריך היומי"}}
          </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text outlined @click="clodeDialog">
                  {{ $t("deleteDialog.cancel") }}
                </v-btn>
                <v-btn
                  color="blue darken-4"
                  outlined
                  text
                  @click="ok"
                  >{{ $t("suggestions.sendSuggestion") }}</v-btn
                >
              </v-card-actions>
        </template>
      </colel-card>
    </v-dialog>
  </div>
</template>

<script>

import {dateToFullHebrew, dateToServer} from "@/utils/dateFormat";

export default {
  name: "deleteDialog",
  components: {

  },
  computed: {
    dateformat() {
      return dateToFullHebrew(this.date);
    }
  },
  props: {
    isShow: Boolean,
    title: String,
  },

  data() {
    return {
      showDialog: false,
      date: new Date(),
      valid: false,
      personObj: null,
    };
  },
  watch: {
    person(newPerson) {
      this.personObj = newPerson;
    },
  },
  methods: {
    clodeDialog() {
      this.date = new Date().toISOString().substr(0, 10),
      this.$emit("closeDialog");
    },
    ok() {
      this.$emit("ok", dateToServer(this.date));
      this.$emit("closeDialog");
    },
  },
};
</script>
