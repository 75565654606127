import Vue from "vue";
import Vuex from "vuex";
import constants from "@/constants";
import axios from "axios";

const XS = 0
const SM = 340
const MD = 540
const LG = 800 - 2
const XL = 1280 - 24

Vue.use(Vuex);

const getScreenSize = (px) => {
  if (px <= SM) {
    return 'xs'
  } else if (px > SM && px <= MD) {
    return 'sm'
  } else if (px > MD && px <= LG) {
    return 'md'
  } else if (px > LG && px <= XL) {
    return 'lg'
  } else if (px > XL) {
    return 'xl'
  }
}
const defaultUser = {
  role: null,
  access_token: localStorage.getItem(
      'token') || null
}

export default new Vuex.Store({
                                state: {
                                  colelIds:null,
                                  submitSettings: localStorage.getItem(
                                      constants.SUBMIT_COLEL_SETTINGS) || false,
                                  colel : null,
                                  user: JSON.parse(JSON.stringify(defaultUser)),
                                  settings: {},
                                  screenSize: getScreenSize(window.innerWidth)
                                },

                                getters: {
                                  isLogin: state => {
                                    return state.user.access_token !== null
                                  },
                                  colelId: state => {
                                    return state.colel.colel.id
                                  },
                                  getRole: state => {
                                    return state.user?.role
                                  },
                                  isAdmin: state => {
                                    return state.user?.role === constants.ADMIN
                                  },
                                  getUserName: state => {
                                    // if (state.user.role === constants.ADMIN) {
                                    //   return state.user.role
                                    // } else {

                                      return state?.colel?.colel?.id
                                    // }
                                  },
                                  getAccessToken: state => {
                                    return state.user.access_token
                                  },
                                  canShowNavigation: state => {
                                    if (state.user.access_token !== null) {
                                      if (state.user.role === constants.ADMIN) {
                                        return true
                                      } else {
                                        return state.submitSettings
                                      }
                                    } else {
                                      return false
                                    }
                                  }
                                },

                                mutations: {
                                  logout(state) {
                                    delete axios.defaults.headers.common["token"];
                                    state.colel = null
                                    state.user =
                                        JSON.parse(JSON.stringify(defaultUser));
                                    state.user.access_token = null
                                  },

                                  setToken(state, token) {
                                    state.user.access_token = token;
                                  },
                                  setUser(state, user) {
                                    state.user = user;
                                  },
                                  setColel(state, colel) {
                                    state.colel = colel;
                                  },
                                  setColelIds(state, colelIds) {
                                    state.colelIds = colelIds;
                                  },
                                  setSubmitSettings(state, isSubmitSettings) {
                                    state.submitSettings = isSubmitSettings;
                                  },
                                  setRole(state, role) {
                                    state.user.role = role;
                                  },
                                  setAccessToken(state, accessToken) {
                                    state.user.access_token = accessToken;
                                  },
                                  setScreenSize(state) {
                                    state.screenSize =
                                        getScreenSize(window.innerWidth)
                                  },
                                  setSettingsAttendanceTypes(state,
                                                             attendanceTypes) {
                                    state.settings.attendanceTypes =
                                        attendanceTypes
                                  }
                                },

                                actions: {
                                  logout({commit}) {
                                    commit("logout");
                                  },
                                  // setUser({commit}, user) {
                                  //   commit("setUser", user);
                                  // },
                                  setRole({commit}, role) {
                                    commit("setRole", role);
                                  },
                                  setToken({commit}, token) {
                                    commit("setToken", token);
                                  },
                                  setColel({commit}, colel) {
                                    commit("setColel", colel);
                                  },
                                  setColelIds({commit}, colelIds) {
                                    commit("setColelIds", colelIds);
                                  },
                                  setAccessToken({commit}, accessToken) {
                                    commit("setAccessToken", accessToken);
                                  },
                                  setSettingsAttendanceTypes({commit},
                                                             attendanceTypes) {
                                    commit("setSettingsAttendanceTypes",
                                           attendanceTypes);
                                  },
                                  setScreenSize({commit}) {
                                    commit("setScreenSize");
                                  },
                                  setSubmitSettings({commit}, isSubmitSettings) {
                                    commit("setSubmitSettings", isSubmitSettings);
                                  }
                                },
                                modules: {}
                              });
