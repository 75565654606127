<template>
<v-list-item-avatar>
            <img src="http://colel.herokuapp.com/image/logo.png">
          </v-list-item-avatar>

</template>

<script>
export default {
 name: "imgBar",
}
</script>

