import VueI18n from "vue-i18n";
import Vue from "vue";
import he from "./he/he-colel-hamesh";
Vue.use(VueI18n);

const messages = {
  he: he,
};

export default new VueI18n({
  locale: "he",
  messages,
});
