<template >
  <div>
  <tableVueColel
	  responsiveLayout="scroll"
	  :expandedRows.sync="expandedRows"
	  sortMode="multiple"
	  :key="tableId"
	  v-on:row-click="rowClick"
	  removableSort
	  :ref="tableId"
	  :value="dataValue"
	  :paginator="true"
	  :selection="selectedItems ? selectedItems : []"
	  :resizableColumns="false"
	  :dataKey="id ? id : 'id'"
	  autoLayout
	  :rowClass="rowClass"
	  class="

      p-datatable-customers
      p-datatable-sm
      p-datatable-gridlines
      p-datatable-striped
    "
	  :rows="rowsToShow"
	  stateStorage="session"
	  :stateKey="tableId"
	  :filters="filters"
	  :loading="isLoading"
	  :sortField="sortField"
	  :sortOrder="1"
	  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
	  :rowsPerPageOptions="[20, 50, 100]"
	  currentPageReportTemplate="מציג {first} עד {last} מתוך {totalRecords}"
  >
    <template #loading>{{ loadingTitle }}</template >
	 <!--       <template #expansion="slotProps">-->
	 <!--           ddddd-->
	 <!--           {{slotProps.data}}-->
	 <!--&lt;!&ndash;            <h5>Orders for {{slotProps.data.name}}</h5>&ndash;&gt;-->

	 <!--    </template>-->
    <slot name="tableContent"></slot >
	 <template #expansion="slotProps">
		<slot :slotProps="slotProps.data" name="tableExpansion"></slot >
<!--            <h5 >{{ slotProps.data }}</h5 >-->
	 </template >


  </tableVueColel >
  </div>
</template >

<script >
import DataTable from './datatable'

export default {
  name: "table",
  components: {
    tableVueColel: DataTable
  },

  props: {
    sortField: String,
    isExpandedRows: Boolean,
    id: String,
    value: Array,
    expandedRows: Array,
    selectedItems: Array,
    tableId: String,
    isLoading: Boolean,
    filters: Object,
    loadingTitle: String,
    rowClass: Function,
    rows: Number
  },
  watch: {
    value(newValue) {
      this.value = newValue;
    },
  },
  computed: {
    dataValue() {
      return this.value;
    },
    rowsToShow(){
      return this.rows ? this.rows : 20
    }
    // expandedRows: {
    //   // getter
    //   get: function () {
    //     return this.expandedRows
    //   },
    //   set: function (newValue) {
    //     this.expandedRows = newValue
    //   },
    // },


  },
  methods: {
    rowClick(e) {
      this.$emit("row-click", e);
    }
  }
};
</script >

<style >
.row-unenabled {
    color: #d7d5d5 !important;
}
</style >