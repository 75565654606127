<template>
   <v-toolbar-title>{{title}}</v-toolbar-title>
</template>

<script>
// import {mapState} from "vuex";


export default {
 name: "titleBar",
  props: {
    title: String,
  },
 computed: {
    // ...mapState(["user"]),
  },

}
</script>

